import React from 'react';
import axios from 'axios';
import './AddUser.css'
import QRCode from 'qrcode.react';
import { baseURL } from './config';

export default function AddUser() {
    const [responseData, setResponseData] = React.useState('');
    const [functionsKey, setFunctionsKey] = React.useState('');
    const [toggleKey, setToggleKey] = React.useState('visible');
    const fetchData = () => {
        axios({
            'method': 'POST',
            'url': baseURL,
            'headers': {
                'x-functions-key':functionsKey,
            }, 
            'params': {
            }
        })
            .then((response) => {
                setResponseData(response.data)
                console.log(responseData)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    return (
        <div className='addUser'>
            <header className='addUser-header'>
                <h1 onClick={e => setToggleKey(toggleKey === 'hidden' ? 'visible' : 'hidden')}>Dodawanie użytkowników</h1>
                <input type='password' id='functionsKey' className={toggleKey} onChange={e => setFunctionsKey(e.target.value)}></input>
                <button type='button' onClick={fetchData} className='av-button'>DODAJ NOWEGO UŻYTKOWNIKA</button>
            </header>
            <main className='addUser-content'>
                {responseData._id &&
                    <QRCode size={250} value={window.location.protocol + '//' + window.location.host + '/register/' + responseData._id} />
                }  
                <br /><a href={window.location.protocol + '//' + window.location.host + '/register/' + responseData._id}>{responseData._id}</a>          
            </main>
        </div>
    );
};
