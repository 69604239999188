import React, { useState, useCallback } from 'react';
import './RegisterForm.css'
import axios from 'axios';
import { baseURL, gameUrl } from './config';
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

export default function VerifyForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = useCallback(async (token) => {
    setLoading(true);
    setIsError(false);
    const data = {
      email: email,
      token: token
    }
    await axios.get(`${baseURL}/Id/${email}`, data).then(res => {
      setUserId(res.data.UserId);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      setIsError(true);
    });
  }, [email, setUserId])



  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    handleSubmit(token);
  }, [executeRecaptcha, handleSubmit]);

  return (
    <div className='register'>
      {
        !userId &&
        <form>
          <div className='input'>
            <input id='email'
              className='av-input'
              type='email'
              name='email'
              required=''
              placeholder='Email *'
              value={email}
              onChange={e => setEmail(e.target.value)} />
            <label className='av-error-msg'>Poprawny email obowiązkowy</label>
          </div>
          <button
            onClick={handleReCaptchaVerify}
            className='av-button'
            disabled={loading}
          >{loading ? 'Wczytuję...' : 'Weryfikacja'}</button>
        </form>
      }
      {userId && <div className='register__play'><a href={gameUrl + '?userId=' + userId} className='av-button'>Graj i wygrywaj</a></div>}

      {isError && <small className='mt-3 d-inline-block text-danger'>Wprowadź poprawne dane.</small>}

    </div>
  );
}
