import React from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import './LeaderBoardList.css'
import { baseURL } from './config';

const delay = 5;

export default function AddUser() {
    const [responseData, setResponseData] = React.useState('');
    const fetchData = React.useCallback(() => {
        axios({
            "method": "GET",
            "url": baseURL,
        })
            .then((response) => {
                setResponseData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },[responseData]);

    function LeadersList(props) {
      const leaders = props.leaders;
      const listItems = leaders.map((data, index) =>
        <li key={index.toString()}>
          {data.name} <strong>({data.score} pkt.)</strong>
        </li>
      );
      return (
        <ol className="leaderBoard__list">{listItems}</ol>
      );
    }

    React.useEffect(() => {
      let timer = setTimeout(() => fetchData(), delay * 1000);
      return () => {
        clearTimeout(timer);
      };
    }, [fetchData])

    return (
        <div className="leaderBoard">
            <header>
                <h1>Zagraj i zgarnij upominek od Avenga. Na Twój wynik czekamy do piątku, do godz.16.00</h1>
            </header>
            <main className="leaderBoard__leftContent">
              {
                responseData &&
                <LeadersList leaders={responseData}></LeadersList>
              }
              <QRCode size={250} value={window.location.protocol + '//' + window.location.host + '/anonymous/register/'} />
            </main>
            <aside className="leaderBoard__video">
              <video className="leaderBoard__videoSrc"  autoPlay='1' muted='1' loop>
                <source src='Avenga_v3.mp4'  type="video/mp4" />
              </video>
            </aside>
        </div>
    );
};
