import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import RegisterForm from './RegisterForm';
import LeaderBoardList from './LeaderBoardList';
import AddUser from './AddUser';
import VerifyForm from './VerifyForm';
import FullList from './FullList';
import RegisterFormAnonymous from './RegisterFormAnonymous';

export default function App() {
  return (
    <Router>
      <div className='content'>
        <header className='header'>
          <div className='header__inner'>
            <div className='header__logo-block'> <a href='https://www.avenga.com/' className='navbar-brand' rel='home'
              title='Avenga'> <img className='logo default' alt='Avenga'
                src='https://www.avenga.com/wp-content/themes/custom-theme/images/logos/logo-positive.svg'></img></a>
            </div>
            <nav className='navbar'>
              <div id='main-menu-links' className='collapse navbar-collapse'>
                <ul id='menu-header-main-manu' className='navbar-nav header-menu'>
                  <li className='menu-item nav-item'>
                    <Link to='/' className='nav-link dropdown-toggle'>Lista</Link>
                  </li>
                  <li className='menu-item nav-item'>
                    <Link to='/register' className='nav-link dropdown-toggle'>Weryfikacja</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        <Switch>
          <Route path='/register/:id'>
            <RegisterForm />
          </Route>
          <Route path='/register'>
            <VerifyForm />
          </Route>
          <Route path='/anonymous/register'>
            <RegisterFormAnonymous />
          </Route>
          <Route path='/add'>
            <AddUser />
          </Route>
          <Route path='/block'>
            <FullList />
          </Route>
          <Route path='/'>
            <LeaderBoardList />
          </Route>
        </Switch>
        <footer className='footer '>
          <div className='footer-waves'>
            <video id='footerVideo' preload='metadata' className='highlight__video' autoPlay='1' muted='1' loop playsInline>
              <source src='https://www.avenga.com/wp-content/themes/custom-theme/images/animation_welle_footer_retina.mp4' type='video/mp4' codecs='avc1.4D401E, mp4a.40.2' />
            </video>
          </div>
        </footer>
      </div>
    </Router>

  );
}
