import React, { useState, useCallback } from 'react';
import axios from 'axios';
import './RegisterForm.css';
import { baseURL, gameUrl } from './config';
import {
  useParams
} from 'react-router-dom';
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

export default function RegisterForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [registred, setRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  let { id } = useParams();

  const handleSubmit = useCallback(async (token) => {
    setLoading(true);
    setIsError(false);
    const data = {
      name: name,
      surname: surname,
      email: email,
      token: token
    }
    await axios.put(`${baseURL}/${id}`, data).then(res => {
      setRegistered(true);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      setIsError(true);
    });
  }, [name, surname, email, id, setRegistered])



  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    handleSubmit(token);
  }, [executeRecaptcha, handleSubmit]);

  return (
    <div className='register'>
      <h1>Rejestracja</h1>
      <h2>
        Klauzula informacyjna Avenga IT Professionals Sp.  z o.o.
      </h2>

      Administratorem Twoich danych osobowych jest Avenga IT Professionals Sp. z o.o.  z siedzibą we Wrocławiu, ul. Gwiaździsta 66, 53-413 Wrocław, kontakt mailowy pod adresem: pl.odo@avenga.com
      Twoje dane osobowe będziemy przetwarzać na podstawie wyrażonej przez Ciebie zgody w celu nawiązania współpracy.
      Pełna treść obowiązku informacyjnego dostępna <a href='https://www.avenga.com/pl/it-professionals/klauzula-informacyjna-information-clause/'>tutaj</a>

      <h2>
        ZGODA
      </h2>

      Wyrażam zgodę na przetwarzanie moich danych osobowych przez Avenga IT Professionals Sp. z o.o. w celu nawiązania współpracy.
      <div className='container'>
        {!registred &&
          <form>
            <div className='input'>
              <input id='name' 
                className='av-input' 
                type='text' 
                name='name' 
                required='' 
                placeholder='Imię *'  
                value={name}
                onChange={e => setName(e.target.value.replace(/\s/g, ''))} />
              <label className='av-error-msg'>Imię tekstowe i obowiązkowe</label>
            </div>

            <div className='input'>
              <input id='surname' 
                className='av-input' 
                type='text' 
                name='surname' 
                required='' 
                placeholder='Nazwisko *'  
                value={surname}
                onChange={e => setSurname(e.target.value.replace(/\s/g, ''))} />
              <label className='av-error-msg'>Nazwiosko tekstowe i obowiązkowe</label>
            </div>

            <div className='input'>
              <input id='email' 
                className='av-input' 
                type='email' 
                name='email' 
                required='' 
                placeholder='Email *'  
                value={email}
                onChange={e => setEmail(e.target.value)} />
              <label className='av-error-msg'>Poprawny email obowiązkowy</label>
            </div>
            <button
              onClick={handleReCaptchaVerify}
              className='av-button'
              disabled={loading}
            >{loading ? 'Wczytuję...' : 'Akceptuję'}</button>
          </form>
        }
        {registred && <div className='register__play'><a href={gameUrl + '?userId=' + id} className='av-button'>Graj i wygrywaj</a></div>}
        {isError && <small className='register__errorMsg'>Wprowadź poprawne dane.</small>}
      </div>
    </div>
  );
}
