import React from 'react';
import axios from 'axios';
import { baseURL, baseURLfull } from './config';

export default function FullList() {
    const [responseData, setResponseData] = React.useState('');
    const [functionsKey, setFunctionsKey] = React.useState('');
    const [functionBlockKey, setFunctionsBlockKey] = React.useState('');

    function FullList(props) {
        const leaders = props.fulllist;
        const listItems = leaders.map((data, index) =>
            <tr key={index.toString()}>
                <td>{data.RowKey}</td>
                <td>{data.name} </td>
                <td>{data.surname}</td>
                <td> {data.email} </td>
                <td><strong>{data.score} pkt. w czasie {data.time} s</strong></td>
                <td><button onClick={e => banUser(data.RowKey)}>BAN</button></td>
            </tr>
        );
        return (
            <table><tr><th>Id</th><th>Imię</th><th>Nazwisko</th><th>email</th></tr>
                {listItems}
            </table>
        );
    }

    const banUser = (id) => {
        axios({
            'method': 'PUT',
            'url': baseURL + "/Block/" + id,
            'headers': {
                'x-functions-key': functionBlockKey,
            },
            'data': {
                block: 'true'
            },
            'params': {
            }
        })
            .then((response) => {
                alert('User ban ' + id)
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const fetchData = () => {
        axios({
            'method': 'GET',
            'url': baseURLfull,
            'headers': {
                'x-functions-key': functionsKey,
            },
            'params': {
            }
        })
            .then((response) => {
                setResponseData(response.data.value)
                console.log(responseData)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    return (
        <div>
            <header className='App-header'>
                <input type='password' id='functionsKey' onChange={e => setFunctionsKey(e.target.value)}></input>
                <button type='button' onClick={fetchData}>GET ALL DATA</button>
            </header>
            <main>
                {responseData &&
                <div>
                    BanFunctionKey: <input type='password' id='functionBlockKey' onChange={e => setFunctionsBlockKey(e.target.value)}></input>
                    <FullList fulllist={responseData} />
                </div>
                    
                }

            </main>
        </div>
    );
};
